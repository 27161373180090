<template>
  <div class="wrapper">
    <!-- 适老化改造 -->
    <!-- <vxe-toolbar>
			<template v-slot:buttons>
				<vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
				<vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
				<vxe-button status="my-orange" icon="" @click="goPage('add')">下载报告</vxe-button>
			</template>
		</vxe-toolbar>-->
    <div>
      <vxe-button status="my-orange" icon="fa fa-plus" @click="addProject()">添加</vxe-button>
<!--      <vxe-button status="my-orange" icon="fa fa-plus" @click="importData">导入</vxe-button>-->
      <!--      <vxe-button status="my-orange" icon="fa fa-plus" @click="exportData">导出</vxe-button>-->
    </div>
    <div style="margin-top: 20px">
      <el-table :data="tableData" border show-overflow-tooltip style="width: 100%">
        <el-table-column align="center" prop="category" label="项目类别"/>
        <el-table-column align="center" prop="name" label="项目名称"/>
        <el-table-column align="center" prop="desc" label="改造内容"/>
        <el-table-column align="center" prop="type" label="项目类型"/>
        <el-table-column align="center" prop="price" label="单价/元" show-overflow-tooltip/>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editClick(scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="delProject(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
          :current-page.sync="tablePage.currentPage"
          :page-size.sync="tablePage.pageSize"
          :page-sizes="tablePage.pageSizes"
          :total="tablePage.totalResult"
          :layouts="layouts"
          @page-change="pageChange"
      />
    </div>

    <el-dialog title="添加项目" :visible.sync="dialogFormVisible" :show-close="false" :close-on-click-modal="false">
      <el-form :model="form" label-position="left">
        <el-form-item label="项目类别" :label-width="formLabelWidth">
          <el-input v-model="form.category" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="项目名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="改造内容" :label-width="formLabelWidth">
          <el-input v-model="form.desc" autocomplete="off"/>
        </el-form-item>
        <el-form-item label="项目类型" :label-width="formLabelWidth">
          <el-select v-model="form.type" filterable style="width:100%" placeholder="请选择项目类型">
            <el-option key="基础" label="基础" value="基础"/>
            <el-option key="可选" label="可选" value="可选"/>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" :label-width="formLabelWidth">
          <el-select v-model="form.state" filterable style="width:100%" placeholder="请选择状态">
            <el-option key="1" label="可用" :value="1"/>
            <el-option key="2" label="不可用" :value="2 "/>
          </el-select>
        </el-form-item>
        <el-form-item label="单价/元" :label-width="formLabelWidth">
          <el-input v-model="form.price" type="number" autocomplete="off"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="()=>{dialogFormVisible = false,form={}}">取 消</el-button>
        <el-button type="primary" @click="addOrEdit()">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        v-loading="uploadLoading"
        element-loading-text="上传中，请勿关闭窗口或切换页面！"
        title="导入人员"
        :visible.sync="importVisible"
        :close-on-click-modal="false"
        width="35%"
    >
      <el-upload class="upload-demo" accept=".xlsx, .xls" action="" :before-upload="handleUpload">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <div class="file-list">
        <p>{{ fileName }}</p>
      </div>
      <div>
        <p class="import-old-person-file">请选择文件小于5M且最多1000条数据的Excel</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importVisible = false">取 消</el-button>
        <el-button type="primary" :disabled="btndisabled" @click="importSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import axios from 'axios'
import api from '@/store/API/api'

export default {
  components: {
    UploadImg
  },
  data() {
    return {
      btndisabled: false,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      form: {},
      chooseId: '',
      fileData: null,
      fileName: '',
      importVisible: false,
      uploadLoading: false,
      // ismore:false,
      tableData: [],
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      }
    }
  },
  created() {
    this.getLists()
  },
  computed: {
    ...mapState([
      'layouts'
    ])
  },
  methods: {
    ...mapActions([
      'getProjectList',
      'projectAdd',
      'projectUpdate',
      'projectDelete',
      'projectDetail'
    ]),
    // 分页功能
    pageChange(item) {
      if (item.type === 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddPerson',
        params: {
          type: type,
          id: itemData.oldPersonId
        },
        query: {
          oldPersonId: itemData.oldPersonId
        }
      })
    },
    // 查询列表
    getLists() {
      const params = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }
      this.getProjectList(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.dataList
          this.tablePage.totalResult = res.data.total
        }
      })
    },
    // 删除
    delProject(row) {
      this.$XModal.confirm('您确定要删除该数据?').then(type => {
        if (type === 'confirm') {
          const params = row.id
          this.projectDelete(params).then(res => {
            if (res.code === 200) {
              this.$message({
                type: 'success',
                message: '删除成功'
              })
              this.getLists()
            }
          })
        } else {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        }
      })
    },
    // 导入功能
    importData() {
      this.importVisible = true
    }, handleUpload(file) {
      const suffix = file.name.split('.')[1]
      if (!['xlsx', 'xls'].includes(suffix)) {
        this.$XModal.message({
          message: '文件格式不正确',
          status: 'warning'
        })
        return false
      }
      const realFilesize = file.size / 1024 / 1023
      if (realFilesize > 5) {
        this.$XModal.message({
          message: '你选择的文件大于5M',
          status: 'warning'
        })
        return false
      }

      this.fileData = file
      this.fileName = file.name
      return false
    },
    importSubmit() {
      if (this.fileData) {
        this.btndisabled = true
        const formData = new FormData()

        formData.append('excelFile', this.fileData)
        // 上传中
        this.uploadLoading = true

        let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
        url += api.importHomeOldPerson
        axios({
          method: 'post',
          data: formData,
          url,
          timeout: 300000,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: sessionStorage.token
          }
        }).then((res) => {
          this.uploadLoading = false
          this.btndisabled = false
          if (res.data.code === 200) {
            this.uploadLoading = false
            this.$message.success('导入成功')
            this.importVisible = false
            this.getLists()
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error',
              showClose: true,
              duration: 0

            })
          }
        })
      } else {
        this.$XModal.message({
          message: '请上传模板文件',
          status: 'warning'
        })
      }
    },
    addProject() {
      this.dialogFormVisible = true
      this.form = {}
      this.chooseId = ''
    },
    editClick(row) {
      this.chooseId = row.id
      this.projectDetail(this.chooseId).then(res => {
        if (res.code === 200) {
          this.form = {
            ...res.data
          }
          this.dialogFormVisible = true
        }
      })
    },
    addOrEdit() {
      if (this.chooseId === '') {
        this.projectAdd(this.form).then(res => {
          if (res.code === 200) {
            this.$message.success('添加成功')
            this.form = {}
            this.dialogFormVisible = false
            this.getLists()
          }
        })
      } else {
        this.projectUpdate(this.form).then(res => {
          if (res.code === 200) {
            this.$message.success('修改成功')
            this.form = {}
            this.dialogFormVisible = false
            this.getLists()
          }
        })
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.wrapper {
  overflow: auto;

  .page {
    overflow: initial;
  }
}
.w-100 {
  width: 100% !important;
}

v-deep .el-form-item__label {
  text-align: left !important;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
</style>
